import { Box, Container, Stack, Typography } from "@mui/material";
import animation from "../assets/anim-404.json";
import Lottie from "lottie-react";
import AppIcon from "./appIcon";

function PageNotFound() {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingY: 4,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: 400
      }}
    >
      <Stack marginBottom="-10vh">
        <Typography variant="h2" color="primary" textAlign="center">
          Sorry
        </Typography>
        <Typography textAlign="center" variant="h6">
          The page does not exist.
        </Typography>
      </Stack>
      <Box flex={1} overflow="hidden">
        <Lottie
          animationData={animation}
          style={{ width: "100%", height: "100%", minHeight: 300 }}
        />
      </Box>
      <AppIcon />
    </Container>
  );
}

export default PageNotFound;
