import { Container, Stack, Typography } from "@mui/material";
import AppIcon from "./appIcon";
import WarningIcon from "@mui/icons-material/Warning";
import { amber } from "@mui/material/colors";

const LinkExpired = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingY: 4,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 400,
      }}
    >
      <WarningIcon
        sx={{ fontSize: 72, marginTop: 4, marginBottom: 2, color: amber[400] }}
      />
      <Stack flex={1} gap={1}>
        <Typography
          variant="h2"
          color="warning"
          textAlign="center"
          fontSize={32}
        >
          Sorry
        </Typography>
        <Typography textAlign="center">
          Your request has expired or the link has already been used.
        </Typography>
      </Stack>
      <AppIcon />
    </Container>
  );
};

export default LinkExpired;
