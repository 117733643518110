import COLORS from "../../../constants/colors";

function WeeklyPlan() {
  const weeks = [
    {
      title: "Sun, 28",
      items: [
        {
          icon: "icon-shopping-bag",
          title: "Shopping",
          isDone: true,
          color: COLORS.amber,
        },
        {
          icon: "icon-cab",
          title: "Buy a new car",
          isDone: true,
          color: COLORS.green_light,
        },
        {
          icon: "icon-heart",
          title: "Pick up my date",
          isDone: true,
          color: COLORS.red,
        }
      ]
    },
    {
      title: "Mon, 29",
      items: [
        {
          icon: "icon-book-open",
          title: "Study",
          isDone: true,
          color: COLORS.blue,
        }
      ]
    },
    {
      title: "Tue, 30",
      items: [
        {
          icon: "icon-book-open",
          title: "Study",
          isDone: true,
          color: COLORS.blue,
        },
        {
          icon: "icon-book-open",
          title: "Hand in reports",
          isDone: false,
          color: COLORS.purple_deep,
        },
      ]
    },
    {
      title: "Wed, 31",
      items: [
        {
          icon: "icon-search",
          title: "Do some research",
          isDone: false,
          color: COLORS.green,
        },
        {
          icon: "icon-coffee",
          title: "Coffee with friends",
          isDone: false,
          color: COLORS.pink,
        },
      ]
    },
    {
      title: "Thu, 1",
      items: [
        {
          icon: "icon-beaker",
          title: "Bio Lab",
          isDone: false,
          color: COLORS.green_light,
        },
      ]
    },
    {
      title: "Fri, 2",
      items: [
        {
          icon: "icon-food",
          title: "Dinner",
          isDone: false,
          color: COLORS.blue_light,
        },
        {
          icon: "icon-glass",
          title: "Party",
          isDone: false,
          color: COLORS.purple,
        },
        {
          icon: "icon-home",
          title: "Go home",
          isDone: false,
          color: COLORS.pink_light,
        },
        {
          icon: "icon-bed",
          title: "Sleep",
          isDone: false,
          color: COLORS.indigo,
        }
      ]
    },
    {
      title: "Sat, 3",
      items: [
        {
          icon: "icon-puzzle",
          title: "Board games with friends",
          isDone: false,
          color: COLORS.orange,
        },
        {
          icon: "icon-glass",
          title: "Party",
          isDone: false,
          color: COLORS.purple,
        },
        {
          icon: "icon-gift",
          title: "Buy some gifts",
          isDone: false,
          color: COLORS.blue,
        },
      ]
    },
  ]
  return (
    <div id="planner-weekly" className="planner-weekly">
      {
        weeks.map((week, wIndex) => (
          <div key={wIndex} className="cell-item-container">
            <div className="cell-item">
              <p className="cell-title">{week.title}</p>
              {
                week.items ? week.items.map((item, index) =>
                  <div key={index} className={`item ${item.isDone ? '-done' : ''}`}>
                    <div className="icon" style={{ color: item.color }}>
                      <i className={item.icon}></i>
                    </div>
                    <span className="title">{item.title}</span>
                  </div>
                ) : null
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default WeeklyPlan;