const AUTH_ERRORS = {
  resetPassword: {
    "auth/expired-action-code": "Your request to reset your password has expired.",
    "auth/invalid-action-code": "Your request to reset your password has already been used.",
    "auth/user-disabled": "This user has been disabled.",
    "auth/user-not-found": "This user was deleted.",
    "auth/weak-password": "The new password is not strong enough.",
  },
  verifyEmail: {
    "auth/expired-action-code": "Your email verification request has expired.",
    "auth/invalid-action-code": "Your email verification request has already been used.",
    "auth/user-disabled": "This user has been disabled.",
    "auth/user-not-found": "This user was deleted.",
  },
  checkActionCode: {
    "auth/expired-action-code": "Your request link has expired.",
    "auth/invalid-action-code": "Your request link has already been used.",
    "auth/user-disabled": "This user has been disabled.",
    "auth/user-not-found": "This user was deleted.",
  }
}

export default AUTH_ERRORS;