import { Avatar, Stack, Typography } from "@mui/material";
import icon from "../assets/app-icon.png";
import { Link } from "react-router-dom";

const ICON_SIZE = 48;
const AppIcon = () => {
  return (
    <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          src={icon}
          variant="rounded"
          sx={{ width: ICON_SIZE, height: ICON_SIZE, borderRadius: 2 }}
        />
        <Typography
          variant="h1"
          sx={{ textTransform: "uppercase", fontSize: 16 }}
        >
          Dreamie Planner
        </Typography>
      </Stack>
    </Link>
  );
};

export default AppIcon;
