import { Box, Button, Container, Stack, Typography } from "@mui/material";
import AppIcon from "./appIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { replace } from "react-router-dom";

const ProcessSuccess = ({ message, continueUrl }) => {

  const handleContinue = () => {
    replace(continueUrl);
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        paddingY: 4,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 400,
      }}
    >
      <CheckCircleIcon
        color="success"
        sx={{ fontSize: 72, marginTop: 4, marginBottom: 2 }}
      />
      <Box flex={1} display="flex" alignItems="center" flexDirection="column">
        <Typography
          variant="h2"
          color="success"
          textAlign="center"
          fontSize={32}
          marginBottom={1}
        >
          Done!
        </Typography>
        <Typography textAlign="center" marginBottom={2}>
          {message}
        </Typography>
        {continueUrl ? (
          <Button
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
            onClick={handleContinue}
          >
            Continue
          </Button>
        ) : null}
      </Box>
      <AppIcon />
    </Container>
  );
};

export default ProcessSuccess;
