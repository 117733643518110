import YearlyPlan from "./mock/yearly";

function YearlyView() {
  return (
    <section className="planner-view -yearly bg-container">
      <div className="wrapper">
        <div className="text-container">
          <h3>Yearly View</h3>
          <p>Plan the whole year with Yearly View. No need to specify a date. Just select a month in the year and plan it. Even better, you don’t have to specify a month.</p>
        </div>
        <div className="image-container">
          {/* <img alt="" src="./img/yearly-plan.png"></img> */}
          <YearlyPlan />
        </div>
      </div>
    </section>
  )
}

export default YearlyView;