import COLORS from "../../../constants/colors";

function MonthlyPlan() {
  const days = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
  ];
  const dates = Array(35).fill(1);
  const datePlans = {
    3: {
      items: [
        {
          icon: "icon-chart-bar",
          title: "Stats",
          isDone: false,
          color: COLORS.amber,
        },
      ]
    },
    13: {
      items: [
        {
          icon: "icon-calc",
          title: "Maths",
          isDone: false,
          color: COLORS.blue,
        },
      ]
    },
    18: {
      items: [
        {
          icon: "icon-beaker",
          title: "Biology",
          isDone: false,
          color: COLORS.green,
        },
      ]
    },
    23: {
      items: [
        {
          icon: "icon-code",
          title: "Programming",
          isDone: false,
          color: COLORS.pink,
        },
      ]
    },
    28: {
      items: [
        {
          icon: "icon-heart",
          title: "Date",
          isDone: false,
          color: COLORS.red,
        },
      ]
    },
  };
  const monthPlans = [
    {
      icon: "icon-book-open",
      title: "Final exams",
      color: COLORS.red,
    },
    {
      icon: "icon-glass",
      title: "Party",
      isDone: false,
      color: COLORS.purple,
    },
  ];

  return <div>
    <div id="planner-monthly" className="planner-monthly">
      {
        days.map((day, dIndex) => (
          <div key={dIndex} className="cell-item-container -day">
            <div className="cell-item">
              <p className="cell-title">{day}</p>
            </div>
          </div>
        ))
      }
      {
        dates.map((val, dIndex) => {
          const date = (dIndex % 29) + val;
          const plan = datePlans[`${dIndex + 1}`] || {};

          return (
            <div key={dIndex} className="cell-item-container">
              <div className="cell-item">
                <p className="cell-title -date">{date}</p>
                {
                  plan.items && plan.items.map((item, index) => (
                    <div key={index} className="item">
                      <div className="icon" style={{ color: item.color }}>
                        <i className={item.icon}></i>
                      </div>
                      <span className="title">{item.title}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        })
      }
    </div>
    <div id="planner-monthly-sticky" className="planner-daily">
      {
        monthPlans.map((item, index) => (
          <div key={index} className={`item ${item.isDone ? '-done' : ''}`}>
            <div className="icon" style={{ color: item.color }}>
              <i className={item.icon}></i>
            </div>
            <span className="title">{item.title}</span>
          </div>
        ))
      }
    </div>
  </div>
}

export default MonthlyPlan;