import WeeklyPlan from "./mock/weekly";

function WeeklyView() {
  return (
    <section className="planner-view -weekly bg-container">
      <div className="wrapper">
        <div className="text-container">
          <h3>Weekly View</h3>
          <p>You can also stay focus on a week using Weekly View. More space for a long list plan.</p>
        </div>
        <div className="image-container">
          {/* <img alt="" src="./img/weekly-plan.png"></img> */}
          <WeeklyPlan />
        </div>
      </div>
    </section>
  )
}

export default WeeklyView;