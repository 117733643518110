function Hero() {
  return (
    <div className="hero bg-container">
      <div className="wrapper">
        <div className="picture-container">
          <img alt="" src="./img/hero-image.png"></img>
        </div>
        <div className="text-container">
          <h1 className="app-name">Dreamie Planner</h1>
          <p>
            Organize your plans, events, or tasks in the simplest way. And make
            these works more enjoyable with some beautiful themes.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.bebonozm.dreamie_planner">
            <img alt="download it on Google Play" src="./img/google-play-badge.png" />
          </a>
          <p className="remark">* iOS app isn't available yet.</p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
