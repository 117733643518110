import {
  Alert,
  Avatar,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import icon from "../assets/app-icon.png";
import { useEffect, useState } from "react";
import _ from "lodash";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSearchParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from "firebase/auth";
import firebaseConfig from "../firebaseConfig.json";
import LinkExpired from "../components/linkExpired";
import ProcessSuccess from "../components/processSuccess";
import AUTH_ERRORS from "../constants/firebaseAuthErrors";

const ICON_SIZE = 56;

const FIELDS = {
  password: "password",
  confirmPassword: "confirm_password",
};
const PATTERN_PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const MESSAGES = {
  password_empty: "Please enter your password.",
  password_confirm_empty: "Please enter confirm password.",
  password_mismatch: "Password and confirm password does not match.",
  password_invalid_format: "Invalid password.",
};

function Account() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [authData, setAuthData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState(false);

  useEffect(() => {
    const mode = searchParams?.get("mode") || "";
    const actionCode = searchParams?.get("oobCode") || "";
    const apiKey = searchParams?.get("apiKey") || "";
    const continueUrl = searchParams?.get("continueUrl") || "";
    // console.log("[Account] params", { mode, actionCode, apiKey, continueUrl });
    if (!(mode && actionCode)) {
      setAuthData(null);
      setLoading(false);
    } else {
      setAuthData({
        mode,
        actionCode,
        apiKey,
        continueUrl,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (!_.isEmpty(authData)) {
      if (authData?.mode === "resetPassword") {
        setLoading(false);
        verifyPasswordResetCode(auth, authData?.actionCode)
          .then(() => {
            // console.log("[Account] verifyPasswordResetCode", email);
            setLoading(false);
          })
          .catch((error) => {
            console.error("[Account] verifyPasswordResetCode", error);
            setAuthData(null);
            setLoading(false);
          });
      } else if (authData?.mode === "verifyEmail" || authData?.mode === "verifyAndChangeEmail") {
        applyActionCode(auth, authData?.actionCode)
          .then(() => {
            setResetSuccess(
              "Your email address has been verified successfully."
            );
            setLoading(false);
          })
          .catch((error) => {
            console.error("[Account] verifyEmail", error);
            setAuthData(null);
            setLoading(false);
          });
      } else if (authData?.mode === "recoverEmail") {
        let restoredEmail = null;
        checkActionCode(auth, authData?.actionCode)
          .then((info) => {
            restoredEmail = info["data"]["email"];
            return applyActionCode(auth, authData?.actionCode);
          })
          .then(() => {
            setResetSuccess(
              `Your account email reverted to ${restoredEmail} successfully.`
            );
            setLoading(false);
          })
          .catch((error) => {
            console.error("[Account] recoverEmail", error);
            setAuthData(null);
            setLoading(false);
          });
      } else {
        setAuthData(null);
        setLoading(false);
      }
    }
  }, [authData]);

  const handleValueChange = (event) => {
    const { name, value } = event?.target;
    // console.log("[Account] handleValueChange", name, value);
    const updateData = { ...data, [name]: value };
    setData(updateData);
  };

  const validate = () => {
    const updateError = Object.assign({}, error);
    const password = data[FIELDS.password];
    const confirmPassword = data[FIELDS.confirmPassword];

    if (password) {
      updateError[FIELDS.password] = PATTERN_PASSWORD.test(password)
        ? ""
        : MESSAGES.password_invalid_format;
    } else {
      updateError[FIELDS.password] = MESSAGES.password_empty;
    }

    if (confirmPassword) {
      updateError[FIELDS.confirmPassword] =
        password === confirmPassword ? "" : MESSAGES.password_mismatch;
    } else {
      updateError[FIELDS.confirmPassword] = MESSAGES.password_confirm_empty;
    }

    setError(updateError);

    const isValid = _.every(updateError, (msg) => !msg);
    return isValid;
  };

  const handleBlur = (event) => {
    const { name } = event?.target;
    // console.log("[Account] handleBlur", name);
    const updateError = { ...error, [name]: "" };
    setError(updateError);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseEventPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseEventConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (validate()) {
      // console.log("[handleSubmit]", data);
      setLoading(true);
      confirmPasswordReset(auth, authData?.actionCode, data[FIELDS.password])
        .then((resp) => {
          // console.log("[handleSubmit] resp", resp);
          setResetSuccess("Reset password successfully.");
          setLoading(false);
        })
        .catch((error) => {
          console.error("[handleSubmit]", error);
          const message =
            AUTH_ERRORS[error?.code] ||
            "Something went wrong. Please try again later.";
          setResetError(message);
          setLoading(false);
        });
    }
  };

  return isLoading ? (
    <Stack height="100vh" gap={2} alignItems="center" justifyContent="center">
      <CircularProgress />
      <Typography>Loading...</Typography>
    </Stack>
  ) : !!resetSuccess ? (
    <ProcessSuccess
      message={resetSuccess}
      continueUrl={authData?.continueUrl}
    />
  ) : authData === null ? (
    <LinkExpired />
  ) : (
    <Container maxWidth="xs" sx={{ paddingY: 4 }}>
      <Stack alignItems="center" spacing={1} marginBottom={4}>
        <Avatar
          src={icon}
          variant="rounded"
          sx={{ width: ICON_SIZE, height: ICON_SIZE, borderRadius: 4 }}
        />
        <Typography
          variant="h1"
          sx={{ textTransform: "uppercase", fontSize: 18 }}
        >
          Dreamie Planner
        </Typography>
      </Stack>
      {!!resetError ? (
        <Alert severity="error" sx={{ marginY: 2 }}>
          {resetError}
        </Alert>
      ) : null}
      <Card sx={{ padding: 2, borderRadius: 2 }}>
        <Typography variant="h3">Create new password</Typography>
        <Typography variant="caption" color="textSecondary">
          Enter your new password below to complete the process.
        </Typography>
        <Stack marginY={2} spacing={2}>
          <TextField
            label="Password"
            variant="outlined"
            size="small"
            name={FIELDS.password}
            onChange={handleValueChange}
            onBlur={handleBlur}
            value={data[FIELDS.password] || ""}
            error={!!error[FIELDS.password]}
            helperText={error[FIELDS.password]}
            slotProps={{
              input: {
                type: showPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseEventPassword}
                      onMouseUp={handleMouseEventPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            size="small"
            name={FIELDS.confirmPassword}
            onChange={handleValueChange}
            onBlur={handleBlur}
            value={data[FIELDS.confirmPassword] || ""}
            error={!!error[FIELDS.confirmPassword]}
            helperText={error[FIELDS.confirmPassword]}
            slotProps={{
              input: {
                type: showConfirmPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseEventConfirmPassword}
                      onMouseUp={handleMouseEventConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Stack>
        <Stack marginY={2}>
          <Typography variant="caption" color="textSecondary">
            Password must be at least 8 characters.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Password must contain:
          </Typography>
          <Typography variant="caption" color="textSecondary">
            • At least 1 upper case English letter.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            • At least 1 lower case English letter.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            • At least 1 digit.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit}
          fullWidth
        >
          Reset Password
        </Button>
      </Card>
    </Container>
  );
}

export default Account;
