import Counter from "../components/counter";
import Footer from "../components/footer";
import Hero from "../components/hero";
import DailyView from "../components/planner_views/daily";
import MonthlyView from "../components/planner_views/monthly";
import WeeklyView from "../components/planner_views/weekly";
import YearlyView from "../components/planner_views/yealy";

function Home() {

  setTimeout(() => {
    const elemYearly = document.getElementById("planner-yearly");
    const elemMonthly = document.getElementById("planner-monthly");
    const elemMonthlySticky = document.getElementById("planner-monthly-sticky");
    const elemWeekly = document.getElementById("planner-weekly");
    const elemDaily = document.getElementById("planner-daily");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("-animate");
        } else {
          entry.target.classList.remove("-animate");
        }
      })
    });
    if (elemYearly)
      observer.observe(elemYearly);
    if (elemMonthly)
      observer.observe(elemMonthly);
    if (elemMonthlySticky)
      observer.observe(elemMonthlySticky);
    if (elemWeekly)
      observer.observe(elemWeekly);
    if (elemDaily)
      observer.observe(elemDaily);
  }, 50);

  return (
    <div className="home">
      <Hero />
      <section>
        <div className="wrapper">
          <h2>Distinguish your plans with a glimpse</h2>
          <div className="text-container">
            <p>
              You can create a plan/task/event with an icon and color. With these
              options, they could help you distinguish each task right away when
              you look at your tight schedule.
            </p>
            <p>
              Dreamie Planner also provides you many cute and beautiful themes.
              Making plan will never be boring ever again!
            </p>
          </div>
        </div>
      </section>
      <Counter />
      <section>
        <h2>Multiple views to plan on</h2>
        <div className="text-container">
          <p>
            Sometimes you just know what you’ll be doing in a specific year or month, don’t know when. Don’t worry, we’ve got your back.
          </p>
        </div>
      </section>
      <YearlyView />
      <MonthlyView />
      <WeeklyView />
      <DailyView />
      <Footer />
    </div>
  );
}

export default Home;
