import MonthlyPlan from "./mock/monthly";

function MonthlyView() {
  return (
    <section className="planner-view -monthly">
      <div className="wrapper">
        <div className="image-container">
          {/* <img alt="" src="./img/monthly-plan.png"></img> */}
          <MonthlyPlan />
        </div>
        <div className="text-container">
          <h3>Monthly View</h3>
          <p>With Monthly View, you can plan the whole month quickly without specifying a date. But you still can specify a date if you want.</p>
        </div>
      </div>
    </section>
  )
}

export default MonthlyView;