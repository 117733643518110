const COLORS = {
    "red": "#f44336",
    "pink": "#E91E63",
    "pink_light": "#F06292",
    "purple": "#9C27B0",
    "purple_deep": "#673AB7",
    "indigo": "#3F51B5",
    "blue": "#2196F3",
    "blue_light": "#03A9F4",
    "cyan": "#00BCD4",
    "teal": "#009688",
    "green": "#4CAF50",
    "green_light": "#8BC34A",
    "lime": "#CDDC39",
    "yellow": "#FFEB3B",
    "amber": "#FFC107",
    "orange_deep": "#FF5722",
    "orange": "#FF7043",
    "brown": "#795548",
    "gray": "#9E9E9E",
    "gray_blue": "#607D8B",
    "black": "#505050"
}

export default COLORS;