function Counter() {
  const items = [
    {
      title: "icons",
      count: "99+"
    },
    {
      title: "colors",
      count: "22"
    },
    {
      title: "themes",
      count: "30"
    }
  ]
  return (
    <div className="counter bg-container">
      <section className="wrapper">
        <h2>Currently we have...</h2>
        <div className="counter-list">
          {
            items.map((item, index) => (
              <div key={index} className="item">
                <h3>{item.count}</h3>
                <p>{item.title}</p>
              </div>
            ))
          }
        </div>
        <p>And there will definitely be more in the future.</p>
      </section>
    </div>
  );
}

export default Counter;
