import COLORS from "../../../constants/colors";

function YearlyPlan() {
  const months = [
    {
      title: "January",
    },
    {
      title: "February",
    },
    {
      title: "March",
      items: [
        {
          icon: "icon-book-open",
          title: "Midterm exams",
          isDone: true,
          color: COLORS.blue,
        },
        {
          icon: "icon-book-open",
          title: "Hand in reports",
          isDone: true,
          color: COLORS.purple_deep,
        },
      ]
    },
    {
      title: "April",
    },
    {
      title: "May",
      items: [
        {
          icon: "icon-book-open",
          title: "Final exams",
          isDone: true,
          color: COLORS.red,
        },
        {
          icon: "icon-glass",
          title: "Party",
          isDone: false,
          color: COLORS.purple,
        },
      ]
    },
    {
      title: "June",
      items: [
        {
          icon: "icon-plane",
          title: "Travel abroad",
          isDone: false,
          color: COLORS.pink,
        }
      ]
    },
    {
      title: "July",
      items: [
        {
          icon: "icon-search",
          title: "Do some research",
          isDone: false,
          color: COLORS.green,
        },
      ]
    },
    {
      title: "August",
      items: [
        {
          icon: "icon-beaker",
          title: "Bio Lab",
          isDone: false,
          color: COLORS.green_light,
        },
      ]
    },
    {
      title: "September"
    },
    {
      title: "October",
      items: [
        {
          icon: "icon-book-open",
          title: "Midterm exams",
          isDone: false,
          color: COLORS.blue,
        },
      ]
    },
    {
      title: "November"
    },
    {
      title: "December",
      items: [
        {
          icon: "icon-book-open",
          title: "Final exams",
          isDone: false,
          color: COLORS.red,
        },
        {
          icon: "icon-gift",
          title: "Buy some gifts",
          isDone: false,
          color: COLORS.blue,
        },
        {
          icon: "icon-puzzle",
          title: "Board games with friends",
          isDone: false,
          color: COLORS.orange,
        },
      ]
    }
  ]
  return (
    <div id="planner-yearly" className="planner-yearly">
      {
        months.map((week, wIndex) => (
          <div key={wIndex} className="cell-item-container">
            <div className="cell-item">
              <p className="cell-title">{week.title}</p>
              {
                week.items ? week.items.map((item, index) =>
                  <div key={index} className={`item ${item.isDone ? '-done' : ''}`}>
                    <div className="icon" style={{ color: item.color }}>
                      <i className={item.icon}></i>
                    </div>
                    <span className="title">{item.title}</span>
                  </div>
                ) : null
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default YearlyPlan;