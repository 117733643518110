import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontFamily: "Mali, sans-serif",
      fontSize: 36
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold"
      // fontFamily: "Barlow Condensed, Segoe UI, Roboto, sans-serif"
    }
  },
  palette: {
    primary: {
      main: '#00c7ac',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffb029',
      contrastText: '#fff',
    },
  },
});

export default theme;