import COLORS from "../../../constants/colors";

function DailyPlan() {
  const items = [
    {
      icon: "icon-shopping-bag",
      title: "Shopping",
      time: "11:00 AM",
      isDone: true,
      color: COLORS.amber,
    },
    {
      icon: "icon-cab",
      title: "Buy a new car",
      time: "1:30 AM",
      isDone: true,
      color: COLORS.green_light,
    },
    {
      icon: "icon-heart",
      title: "Pick up my date",
      time: "5:30 PM",
      isDone: true,
      color: COLORS.red,
    },
    {
      icon: "icon-food",
      title: "Dinner",
      time: "6:00 PM",
      isDone: false,
      color: COLORS.blue_light,
    },
    {
      icon: "icon-glass",
      title: "Party",
      time: "8:00 PM",
      isDone: false,
      color: COLORS.purple,
    },
    {
      icon: "icon-home",
      title: "Go home",
      time: "10:00 PM",
      isDone: false,
      color: COLORS.pink_light,
    },
    {
      icon: "icon-bed",
      title: "Sleep",
      time: "10:30 PM",
      isDone: false,
      color: COLORS.indigo,
    }
  ];
  return (
    <div id="planner-daily" className="planner-daily">
      {
        items.map((item, index) => (
          <div key={index} className={`item ${item.isDone ? '-done' : ''}`}>
            <div className="icon" style={{ color: item.color }}>
              <i className={item.icon}></i>
            </div>
            <span className="title">{item.title}</span>
            <span className="time">{item.time}</span>
          </div>
        ))
      }
    </div>
  )
}

export default DailyPlan;