import DailyPlan from "./mock/daily";

function DailyView() {
  return (
    <section className="planner-view -daily">
      <div className="wrapper">
        <div className="image-container">
          {/* <img alt="" src="./img/daily-plan.png"></img> */}
          <DailyPlan />
        </div>
        <div className="text-container">
          <h3>Daily View</h3>
          <p>Or if your schedule is too tight, you can use Daily View. Cross finished tasks to stay focus on the next ones.</p>
        </div>
      </div>
    </section>
  )
}

export default DailyView;